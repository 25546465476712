(function (angular) {
    'use strict';

    angular.module('friburgoeletro')
        .constant('alertify', window.alertify)
        .constant('$', $)
        .config(config);

    /* @ngInject */
    function config($httpProvider, $asideProvider, alertify) {

        angular.extend($asideProvider.defaults, {
            container: 'body',
            html: true
        });

        // Busy Indicator Interceptor
        $httpProvider.interceptors.push(['$q', '$rootScope',
            function ($q, $rootScope) {
                var numLoadings = 0;

                return {
                    request: function (config) {

                        numLoadings++;
                        $rootScope.isBusy = true;
                        return config || $q.when(config);

                    },
                    response: function (response) {

                        if ((--numLoadings) === 0)
                            $rootScope.isBusy = false;

                        return response || $q.when(response);

                    },
                    responseError: function (response) {

                        if (!(--numLoadings))
                            $rootScope.isBusy = false;

                        return $q.reject(response);
                    }
                };
            }]);

        $httpProvider.interceptors.push([
            '$q', function ($q) {
                return {
                    'responseError': function (response) {
                        switch (response.status) {
                            case 401:
                                console.info('Usuário ou senha inválidos!');
                                break;
                            case 403:
                                alertify.error("Você precisa fazer login antes de executar esta ação!");
                                break;
                            case 500:
                                console.info('Erro interno no servidor: ' + response.data);
                                break;
                            default:
                                console.info('Erro: ' + response.status + ': ' + response.data);
                        }

                        return $q.reject(response);
                    }
                };
            }
        ]);
    }

})(angular);