(function (angular) {
    angular
        .module('friburgoeletro')
        .controller("PedidosController", [
            '$scope', '$http', 'alertify', function ($scope, $http, alertify) {

                $scope.isLoading = false;

                $scope.page = 1;

                $scope.data = [];

                $scope.detailModel = {};

                $scope.read = function () {
                    $scope.isLoading = true;
                    $http.get("/Admin/PedidosRead/" + $scope.page)
                        .success(function (data) {

                            var d = data.Data;

                            $(d).each(function (i, el) {

                                var status = el.Status;

                                el.StatusNumber = status;

                                switch (status) {
                                    case 0:
                                        el.Status = "Aprovado";
                                        break;
                                    case 1:
                                        el.Status = "Reprovado";
                                        break;
                                    case 2:
                                        el.Status = "Pagamento Aprovado";
                                        break;
                                    case 3:
                                        el.Status = "Pagamento Reprovado";
                                        break;
                                    case 4:
                                        el.Status = "Em Transporte";
                                        break;
                                    case 5:
                                        el.Status = "Finalizado";
                                        break;
                                    case 6:
                                        el.Status = "Aguardando Pagamento";
                                        break;
                                    case 7:
                                        el.Status = "Aguardando Liberação";
                                        break;
                                    case 8:
                                        el.Status = "Pré Venda";
                                        break;
                                    case 9:
                                        el.Status = "Aguardando Separação";
                                        break;
                                    case 10:
                                        el.Status = "Separado";
                                        break;
                                }

                            });

                            $scope.data = $scope.data.concat(d);

                        })
                        .error(function (data, status, headers, config) {

                        }
                        ).then(function () {
                            $scope.isLoading = false;
                        });
                };

                $scope.loadMore = function () {
                    $scope.page = $scope.page + 1;

                    $scope.read();
                };

                $scope.setDetailModel = function (item) {
                    console.log(item);
                    $scope.detailModel = item;
                };

                $scope.printBoleto = function (item) {
                    $http.get('/Pedido/PrintBoleto/' + item.Id)
                        .success(function (data) {
                            var win = window.open(data.Url, 'Imprimir Boleto', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=800, height=600');

                            try {
                                win.focus();
                            } catch (e) {
                                alertify.error('Os popups estão bloqueados em seu navegador, por favor permita que esta página exiba popups nas configurações de seu navegador!');
                            }
                        });
                };

                $scope.getDanfe = function (item) {
                    $http.get('/Admin/CheckXml/' + item.Id)
                        .success(function (data) {
                            if (!data.Success) {
                                alertify.error('Nota Fiscal não diponível no momento. Tente novamente mais tarde!');
                                return;
                            }

                            var win = window.open('/Admin/GetDanfe/' + item.Id, 'Imprimir Danfe', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=800, height=600');

                            try {
                                win.focus();

                            } catch (e) {
                                alertify.error('Os popups estão bloqueados em seu navegador, por favor permita que esta página exiba popups nas configurações de seu navegador!');
                            }

                        }).error(function () {
                            alertify.error('Nota Fiscal não diponível no momento. Tente novamente mais tarde!');
                        });
                }

                $scope.getXml = function (item) {
                    $http.get('/Admin/CheckXml/' + item.Id)
                        .success(function (data) {
                            if (!data.Success) {
                                alertify.error('Nota Fiscal não diponível no momento. Tente novamente mais tarde!');
                                return;
                            }

                            var win = window.open('/Admin/DownloadXml/' + item.Id, '_blank');

                            try {
                                win.focus();
                            } catch (e) {
                                alertify.error('Os popups estão bloqueados em seu navegador, por favor permita que esta página exiba popups nas configurações de seu navegador!');
                            }

                        }).error(function () {
                            alertify.error('Nota Fiscal não diponível no momento. Tente novamente mais tarde!');
                        });
                }

                $scope.read();
            }
        ]);
})(angular);