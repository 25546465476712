(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .filter("aspnetJsonDate", function() {
            return function(value) {

                var pattern = /Date\(([^)]+)\)/;
                var results = pattern.exec(value);
                var dt = new Date(parseFloat(results[1]));
                return +dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();

            };
        });
})(angular);