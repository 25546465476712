(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .factory('Ticket', ticket);

    /* @ngInject */
    function ticket($http, $rootScope, alertify) {
        var service = {};

        service.data = [];
        
        service.read = function () {

            $http.get("/Ticket/Read").error(function (data) {

            }).success(function (data) {
                service.data = data;

            }).then(function () {

            });
        };
        
        return service;
    }
})(angular);
