(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller('CheckoutController', checkoutController);

    /* @ngInject */
    function checkoutController($scope, $rootScope, $http, $window, $modal, $, Cart) {

        var vm = this;

        vm.Cart = Cart;
        vm.isCompleteContaEnabled = false;
        vm.isEnderecoSelected = false;
        vm.completeCadastroModel = {};
        vm.paymentMethods = {};
        vm.paymentMethod = null;
        vm.card = null;
        vm.cardBrand = null;
        vm.cardInstallments = null;
        vm.cardInstallment = null;
        vm.boletoLink = null;

        $($window.document)
            .on('login.success ', checkConta);

        vm.finalizar = finalizar;
        vm.getCreditCardToken = getCreditCardToken;
        vm.getCreditCardBrand = getCreditCardBrand;
        vm.getCreditCardInstallments = getCreditCardInstallments;
        vm.setPaymentMethod = setPaymentMethod;
        vm.setCardInstallment = setCardInstallment;
        vm.checkConta = checkConta;
        vm.boletoText = "";


        // Mock
        vm.card = {
            number: '4111111111111111',
            expMonth: '12',
            expYear: '2030',
            name: 'JOAO DA SILVA',
            ccv: '123',
            ddd: '22',
            cpf: '11111111111',
            phone: '999999999',
            dataNasc: '17/12/1992'
        };

        // Complete Cadastro Modal
        var completeCadastroModalVm = $scope;
        completeCadastroModalVm.completeCadastro = completeCadastro;
        vm.completeContaModal = $modal({
            scope: completeCadastroModalVm,
            templateUrl: '/Templates/complete-conta',
            show: vm.isCompleteContaEnabled
        });

        getPaymentMethods();

        $http
            .get('/Common/Parametro/?nome=VipCommerce.BoletoProcedimentos')
            .then(function (res) {
                vm.boletoText = res.data.Valor;
            }).catch(function (e) {
            console.log(e);
        });

        function setPaymentMethod(method) {

            if (vm.card.cpf != "") {
                vm.paymentMethod = method;
            }
        }

        function setCardInstallment(model) {
            vm.cardInstallment = model;
        }

        function getPaymentMethods() {
            $rootScope.isBusy = true;
            PagSeguroDirectPayment.getPaymentMethods({
                amount: Cart.total(),
                success: function (res) {
                    for (var key in res.paymentMethods) {

                        if (key !== 'BOLETO' && key !== 'CREDIT_CARD') continue;

                        var method = res.paymentMethods[key];

                        method.display = method.name === 'BOLETO' ? 'Boleto' : 'Cartão';

                        vm.paymentMethods[key] = method;
                    }

                    $rootScope.isBusy = false;
                },
                error: function (res) {
                    console.log(res);
                    $rootScope.isBusy = false;
                }
            });
        }

        function getCreditCardBrand() {

            if (!vm.card || !vm.card.number) {
                vm.cardBrand = null;
                return;
            }

            var bin = vm.card.number.replace(/\s+/g, '').substr(0, 6);

            $rootScope.isBusy = true;

            PagSeguroDirectPayment.getBrand({
                cardBin: bin,
                success: function (res) {
                    vm.cardBrand = res.brand;
                    vm.getCreditCardToken();
                    vm.getCreditCardInstallments();
                    $rootScope.isBusy = false;
                },
                error: function (res) {
                    console.log(res);
                    vm.cardBrand = null;
                    $rootScope.isBusy = false;
                }
            });
        }

        function getCreditCardToken() {
            $rootScope.isBusy = true;
            PagSeguroDirectPayment.createCardToken({
                cardNumber: vm.card.number,
                brand: vm.cardBrand.name,
                cvv: vm.card.ccv,
                expirationMonth: vm.card.expMonth,
                expirationYear: vm.card.expYear,
                success: function (resp) {
                    vm.card.token = resp.card.token;
                    $rootScope.isBusy = false;
                },
                error: function (err) {
                    console.log(err);
                    $rootScope.isBusy = false;
                }
            });

        }

        function getCreditCardInstallments() {
            $rootScope.isBusy = true;
            PagSeguroDirectPayment.getInstallments({
                amount: Cart.totalWithFrete(),
                brand: vm.cardBrand.name,
                maxInstallmentNoInterest: 12,
                success: function (response) {
                    vm.cardInstallments = response.installments[vm.cardBrand.name];
                    $rootScope.isBusy = false;
                },
                error: function (response) {
                    console.log(response);
                    $rootScope.isBusy = false;
                }
            });
        }

        function finalizar() {

            if ($rootScope.isBusy) return;

            var reqObj = {};

            if (vm.paymentMethod === 'boleto') {
                reqObj = {
                    transactionType: vm.paymentMethod
                };
            } else {
                reqObj = {
                    transactionType: vm.paymentMethod,
                    ccToken: vm.card.token,
                    numParcelas: vm.cardInstallment.quantity,
                    valorParcela: vm.cardInstallment.installmentAmount,
                    ccName: vm.card.name,
                    ccCpf: vm.card.cpf,
                    ccDdd: vm.card.ddd,
                    ccPhone: vm.card.phone,
                    ccDataNasc: vm.card.dataNasc
                };
            }

            $rootScope.isBusy = true;

            $http({
                method: 'POST',
                url: '/Pedido/Finalizar',
                data: $.param(reqObj),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            }).then(function (res) {
                $rootScope.isBusy = false;

                if (res.data.Success === true) {
                    Cart.reset();
                    $window.location = '/Pedido/Success' + (vm.paymentMethod === 'boleto' ? '?boletoLink=' + res.data.Data : '');
                } else {
                    if (res.data.ex == "") {
                        alertify.error('Tivemos um problema ao tentar finalizar o pedido, por favor tente novamente mais tarde!');
                    } else {
                        alertify.error(res.data.Message);
                    }
                }
            });
        }

        function checkConta() {
            console.log('checkConta');

            $http.get('/Conta/IsUserDataValid').then(function (res) {
                if (!res.data) {
                    console.log(res.data);
                    vm.isCompleteContaEnabled = true;
                    vm.completeContaModal.$promise.then(vm.completeContaModal.show);
                } else {
                    window.location.hash = 'entrega';
                }
            });
        }

        function completeCadastro(model) {
            $http.post('/Conta/CompleteCadastro', model)
                .success(function (resp) {
                    if (resp.Success === true) {
                        vm.completeContaModal.$promise.then(vm.completeContaModal.hide);
                        window.location.hash = 'entrega';
                    } else {
                        alertify.error(resp.Message);
                    }
                }).error(function () {
                alertify.error('Os dados informados são inválidos!');
            });
        }

        //checkConta();
    }
})(angular);
