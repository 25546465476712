(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller("CartController", cartController);

    /* @ngInject */
    function cartController($scope, $rootScope, $window, Cart) {

        var vm = this;

        vm.added = false;
        vm.cart = Cart;
        vm.cart.read();

        $window.onload = function () {
            vm.cart.read();
        };

        $rootScope.$on("Cart.add", function () {

            vm.added = true;

            setTimeout(function () {

                vm.cart.read();

                $scope.$apply(function () {
                    vm.added = false;
                });

            }, 100);
        });
    }
})(angular);
