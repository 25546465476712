(function (angular) {
    angular
        .module('friburgoeletro')
        .controller("ClientesController", [
            '$scope', '$http', function ($scope, $http) {

                $scope.isLoading = false;

                $scope.data = [];

                $(document).on('cadastro.success', function () {
                    $scope.read();
                });

                $scope.read = function () {
                    $scope.isLoading = true;
                    $http.get("/Admin/ClientesRead")
                        .success(function (data, status, headers, config) {

                            //pega os dados do response
                            $scope.data = data.Data;

                            //para cada revendedor...
                            for (var i = 0; i < $scope.data.length; i++) {
                                
                                //cria um array chart que vai receber os dados do gráfico
                                $scope.data[i].chart = [];

                                //cria um array para receber os pedidos por mês
                                $scope.data[i].list = [];

                                //cria uma variável para armazenar o valor para se calcular a porcentagem das progress bars
                                $scope.data[i].baseCalculo = 0;

                                //cria uma variável para armazenar o valor total dos pedidos
                                $scope.data[i].valorTotal = 0;

                                //preenche o array chart com um objeto para cada mês, com valor 0
                                for (var mes = 1; mes <= 12; mes++) {
                                    $scope.data[i].chart.push({

                                        //mês do ano
                                        label: beautify(mes),

                                        //valor dos pedidos naquele mês
                                        value: 0,

                                        //porcentagem em relação ao valor total de pedidos no ano
                                        percentage: 0
                                    });
                                }

                                //pega todos os pedidos do revendedor
                                var pedidos = $scope.data[i].Pedidos;

                                //para cada pedido
                                for (var j in pedidos) {

                                    //pega a data do pedido
                                    var dataPedido = toDate(pedidos[j].Data);

                                    pedidos[j].Data = dataPedido;

                                    //extrai o mês em que o pedido foi feito
                                    var mes = dataPedido.getMonth();

                                    //soma o valor do pedido no objeto do chart correspondente ao mês
                                    $scope.data[i].chart[mes - 1].value += pedidos[j].Total;

                                    //adiciona o pedido na lista de pedidos por mês
                                    addListItem($scope.data[i].list, beautify(mes), pedidos[j]);

                                    //PARA UTILIZAR A SOMA DE TODOS OS VALORES MENSAIS COMO PARÂMETRO DA PORCENTAGEM, DESCOMENTE ESTA LINHA
                                    //atualiza o valor da base de cálculo da porcentagem
                                    //$scope.data[i].baseCalculo += pedidos[j].Total;

                                    //atualiza o valor total dos pedidos
                                    $scope.data[i].valorTotal += pedidos[j].Total;
                                }

                                //PARA UTILIZAR O MAIOR VALOR MENSAL COMO PARÂMETRO DA PORCENTAGEM, DESCOMENTE ESTA LINHA
                                $scope.data[i].baseCalculo = _.max($scope.data[i].chart, function (item) { return item.value; }).value;

                                //depois que o valor total foi calculado e o chart foi preenchido...

                                //faz a iteração nos objetos do chart, um para cada mês do ano
                                for (var k in $scope.data[i].chart) {

                                    //pega o valor do objeto
                                    var current = $scope.data[i].chart[k].value;

                                    //calcula a porcentagem da progress bar
                                    var percentage = calcPercentage(current, $scope.data[i].baseCalculo);

                                    //atribui a porcentagem ao objeto do chart
                                    $scope.data[i].chart[k].percentage = percentage;
                                }

                            }

                        })
                        .error(function (data, status, headers, config) {

                        }
                        ).then(function () {
                            $scope.isLoading = false;
                        });
                };
                $scope.read();

                function beautify(mes) {
                    switch (mes) {
                        case 1:
                            return "JAN";
                        case 2:
                            return "FEV";
                        case 3:
                            return "MAR";
                        case 4:
                            return "ABR";
                        case 5:
                            return "MAI";
                        case 6:
                            return "JUN";
                        case 7:
                            return "JUL";
                        case 8:
                            return "AGO";
                        case 9:
                            return "SET";
                        case 10:
                            return "OUT";
                        case 11:
                            return "NOV";
                        case 12:
                            return "DEZ";
                        default:
                            return "";
                    }
                }

                function calcPercentage(current, total) {
                    return current * 100 / total;
                }

                function addListItem(list, mes, pedido) {
                    var item = _.findWhere(list, { label: mes });

                    if (item) {
                        item.pedidos.push(pedido);
                    } else {
                        list.push({
                            label: mes,
                            pedidos: [pedido]
                        });
                    }
                }

                function toDate(str) {
                    var parts = str.split('/');
                    var d = parts[0];
                    var m = parts[1];
                    var y = parts[2];

                    return new Date(y, m, d, 0, 0, 0, 0);
                }
            }
        ]);
})(angular);