(function (angular) {
    'use strict';

    angular.module('friburgoeletro')
        .factory("$focus", [
            '$timeout', function($timeout) {
                return function(id) {
                    $timeout(function() {
                        var element = document.getElementById(id);
                        if (element) {
                            element.focus();
                            element.select();
                        }
                    });
                };
            }
        ]);

    angular.module('friburgoeletro')
        .directive("ngFocus", [
            '$timeout', function($timeout) {
                return function(scope, elem, attrs) {
                    scope.$watch(attrs.ngFocus, function(newval) {
                        if (newval) {
                            $timeout(function() {
                                elem[0].focus();
                            }, 0, false);
                        }
                    });
                };
            }
        ]);
})(angular);