(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .factory('Catalog', catalog);

    /* @ngInject */
    function catalog($resource) {
        return $resource('/Catalogo/Read');
    }
})(angular);