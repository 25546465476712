/**
 * Created by mayke on 30/09/15.
 */
(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .directive('busyIndicator', busyIndicator);

    function busyIndicator() {
        var directive = {
            transclude: true,
            replace: true,
            scope: {
                active: '=',
                message: '='
            },

            template: '<div class="busy-indicator fadein fadeout" ng-show="active">' +
                '<div class="busy-indicator-container"><spinner class="busy-indicator-spinner"></spinner>' +
                '<div class="busy-indicator-message overlay-message" ng-show="message">{{message}}</div>' +
                '</div>' +
                '</div>',

            restrict: 'E',
            link: link
        };

        function link(scope, element, attrs) {

            $(element).on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
            });

            $(window).on('resize', refresh);

            refresh();

            function refresh() {
                var cont = $(element).find('.busy-indicator-container');

                $(element).css({
                    width: $(window).width(),
                    height: $(window).height()
                });

                $(cont).css({
                    left: ($(element).width() / 2) - ($(cont).width() / 2),
                    top: ($(element).height() / 2) - ($(cont).height() / 2)
                });
            }
        }

        return directive;
    }
})(angular);