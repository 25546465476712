(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .factory("$scopes", function() {
            var mem = {};

            return {
                store: function(key, value) {
                    mem[key] = value;
                },
                get: function(key) {
                    return mem[key];
                }
            };
        });
})(angular);