(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller('GlobalController', globalController);

    /* @ngInject */
    function globalController($scope, $rootScope, $timeout, $http) {

        $scope.searchText = '';
        $scope.searchResultData = [];
        $scope.isSearchResultOpen = false;
        $scope.showSearchResult = showSearchResult;
        $scope.hideSearchResult = hideSearchResult;

        var filterTextTimeout;

        $scope.$watch('searchText',
            function (val) {
                if (filterTextTimeout) $timeout.cancel(filterTextTimeout);

                filterTextTimeout = $timeout(function () {
                    search(val);
                }, 500);
            });

        function showSearchResult() {
            $scope.isSearchResultOpen = true;
        }

        function hideSearchResult() {
            $scope.isSearchResultOpen = false;
        }

        function search(text) {

            if (text.length < 3) return;

            $http({
                url: '/Catalogo/',
                params: {
                    json: true,
                    filter: text,
                    pageSize: 5
                },
                method: 'GET'
            }).then(function (res) {
                $scope.searchResultData = res.data.Data;
                $scope.showSearchResult();
            });
        }
    }
})(angular);
