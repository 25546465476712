(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller("ProductController", productController);


    /* @ngInject */
    function productController($scope, $http, $focus, alertify, Cart) {

        $scope.Cart = Cart;

        $scope.IsGrid = false;

        $scope.model = {};

        $scope.selectionModel = {};

        $scope.gridData = [];

        $scope.hasInGrid = function (colorId, valCaractId) {
            return _.some($scope.gridData.Produtos, function (x) {
                return (!colorId || x.CorId === colorId) && (x.ValorCaracteristicaId === valCaractId);
            });
        };

        $scope.readGridData = function (id) {

            $http.get("/Produto/GetGrade", {
                params: {
                    id: id
                }
            }).then(
                function (res) {
                    $scope.IsGrid = true;
                    $scope.gridData = res.data;

                    console.log(res);
                    if (!$scope.gridData || !$scope.gridData.Produtos || !$scope.gridData.Produtos.length > 0) return;

                    var first = $scope.gridData.Produtos[0];

                    $scope.selectionModel = {
                        colorId: first.CorId,
                        sizeId: first.ValorCaracteristicaId
                    };

                    $scope.setModel();
                },
                function(err) { console.log(err); }
            )};

        $scope.setModel = function () {

            $scope.model = _.find($scope.gridData.Produtos, function (x) {
                return x.CorId === $scope.selectionModel.colorId &&
                    x.ValorCaracteristicaId === $scope.selectionModel.sizeId;
            });

            $scope.model.qtd = 1;

            $focus("qtd");
        };

        $scope.isQtdFocused = false;

        $scope.sizeFilter = function (colorId) {
            return function (size) {
                return _.some($scope.gridData.Produtos, function (x) {
                    return x.CorId === colorId &&
                        x.ValorCaracteristicaId === size.ValorId;
                });
            };
        };

        $scope.colorFilter = function (color) {
            return _.some($scope.gridData.Produtos, function (x) {
                return x.CorId === color.Id;
            });
        };

        $scope.addToCart = function () {
            if (!$scope.model) return;

            Cart.add($scope.model.Id, $scope.model.qtd);
            $scope.model.qtd = 1;
        };
    }
})(angular);
