(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller("EnderecoController", enderecoController);

    /* @ngInject */
    function enderecoController($scope, $rootScope, $http, $window, alertify, Cart) {

        $scope.isAddEnabled = false;
        $scope.isSearchEnabled = true;
        $scope.selectedModelId = null;
        $scope.isSelected = false;
        $scope.address = "";
        $scope.model = {
            cep: "",
            numero: 0,
            complemento: "",
            estado: "",
            cidade: "",
            bairro: "",
            endereco: ""
        };

        $scope.data = [];
        $scope.estadoData = [];
        $scope.cidadeData = [];
        $scope.bairroData = [];
        $scope.enderecoData = [];
        $scope.clienteEnderecoData = [];

        $scope.isEstadoEnabled = false;
        $scope.isCidadeEnabled = false;
        $scope.isBairroEnabled = false;
        $scope.isEnderecoEnabled = false;

        //#region Enderecos

        $scope.readEnderecos = function(bairroId) {
            $rootScope.isLoading = true;
            $http.get("/Enderecos/Enderecos", {
                    params: {
                        id: bairroId
                    }
                })
                .error(function() {

                })
                .success(function(data) {
                    $scope.enderecoData = data;
                }).then(function() {
                    $rootScope.isLoading = false;
                });
        };

        $scope.enderecoChanged = function(endereco) {
            $scope.model.CEP = $scope.model.endereco.Cep;
            $scope.updateAddressText();
            console.log($scope.model.endereco.Cep);
            console.log($scope.model.endereco.Id);
            //$scope.
            // $scope.model.endereco = $scope.data.EnderecoModel || null;
            //$scope.cepChanged();
        };

        //#endregion

        //#region Bairros

        $scope.readBairros = function(cidadeId) {
            $rootScope.isLoading = true;
            $http.get("/Enderecos/Bairros", {
                    params: {
                        id: cidadeId
                    }
                })
                .error(function() {

                })
                .success(function(data) {
                    $scope.bairroData = data;
                }).then(function() {
                    $rootScope.isLoading = false;
                });
        };
        $scope.bairroChanged = function() {
            $scope.readEnderecos($scope.model.bairro.Id);
        };

        //#endregion

        //#region Cidades

        $scope.readCidades = function(estadoId) {
            $rootScope.isLoading = true;
            $http.get("/Enderecos/Cidades", {
                    params: {
                        id: estadoId
                    }
                })
                .error(function() {

                })
                .success(function(data) {
                    $scope.cidadeData = data;
                }).then(function() {
                    $rootScope.isLoading = false;
                });
        };
        $scope.cidademChanged = function() {
            $scope.readBairros($scope.model.cidade.Id);
        };

        //#endregion

        //#region Estados

        $scope.readEstados = function() {
            $rootScope.isLoading = true;
            $http.get("/Enderecos/Estados")
                .error(function() {

                })
                .success(function(data) {
                    $scope.estadoData = data;
                }).then(function() {
                    $rootScope.isLoading = false;
                });
        };
        $scope.readEstados();
        $scope.estadoChanged = function() {
            $scope.readCidades($scope.model.estado.Id);
        };

        //#endregion

        $scope.searchMode = function() {
            $scope.isSearchEnabled = !$scope.isSearchEnabled;
            $scope.resetModel();
            $scope.read();
        };

        $scope.closeAdd = function() {
            $scope.isAddEnabled = false;
            $scope.resetModel();
        };

        $scope.cepChanged = function () {
            console.log("cepc");

            if (!$scope.model || !$scope.model.cep || $scope.model.cep.length <= 0) return;

            console.log("cepcb");
            $rootScope.isLoading = true;
            $scope.isCepLoading = true;
            $scope.data = [];
            $scope.address = null;
            

            $http.get("/Enderecos/Cep", {
                    params: {
                        id: $scope.model.cep
                    }
                })
                .error(function () {
                    $scope.resetEndereco();
                    alertify.error("CEP não encontrado!");
                })
                .success(function(data) {

                    if (!data) {
                        $scope.resetEndereco();
                        alertify.error("CEP não encontrado!");
                        return;
                    }

                    $scope.isEnderecoEnabled = data.EnderecoModel.Nome == "" ? true : false;
                    $scope.isEstadoEnabled = data.EstadoModel.Nome == "" ? true : false;
                    $scope.isCidadeEnabled = data.CidadeModel.Nome == "" ? true : false;
                    $scope.isBairroEnabled = data.BairroModel.Nome == "" ? true : false;

                    $scope.data = data;

                    
                    $scope.model.estado = $scope.data.EstadoModel.Nome || null;
                    $scope.model.cidade = $scope.data.CidadeModel.Nome || null;
                    $scope.model.bairro = $scope.data.BairroModel.Nome || null;
                    $scope.model.endereco = $scope.data.EnderecoModel.Nome || null;

                    //$scope.updateAddressText();

                }).then(function() {
                    $rootScope.isLoading = false;
                    $scope.isCepLoading = false;
                });
        };

        $scope.select = function(id) {
            $http.post("/Pedido/SelectEndereco/" + id)
                .error(function() {
                    $scope.selectedModelId = null;
                    alertify.error("Houve um problema ao selecionar o endereço, tente novamente mais tarde!");
                })
                .success(function(resp) {

                    if (resp.Success === false) {
                        $scope.isSelected = false;
                        $scope.selectedModelId = null;
                        alertify.error("Houve um problema ao selecionar o endereço, tente novamente mais tarde!");
                    }

                    $scope.selectedEnderecoId = id;
                    $scope.isSelected = true;
                    $scope.calcFrete();
                });
        };

        $scope.delete = function(id) {

            if (!confirm("Tem certeza que deseja excluir?")) return;

            $http.post("/Pedido/RemoveEndereco", {
                    id: id
                })
                .success(function(resp) {
                    if (resp.Success === true) {
                        $scope.read();
                        $scope.isAddEnabled = false;
                        alertify.success(resp.Message);
                    } else {
                        alertify.error(resp.Message);
                    }
                });
        };
        $scope.read = function() {
            $scope.isEnderecoLoading = true;

            $scope.isEstadoEnabled = false;
            $scope.isCidadeEnabled = false;
            $scope.isBairroEnabled = false;
            $scope.isEnderecoEnabled = false;

            $scope.clienteEnderecoData = [];
            $http.get("/Pedido/EnderecoData")
                .error(function() {
                    $scope.clienteEnderecoData = null;
                })
                .success(function(data) {
                    $scope.clienteEnderecoData = data;
                }).then(function() {
                    $scope.isEnderecoLoading = false;
                });
        };

        $scope.updateAddressText = function() {
            if ($scope.model.endereco === null) {
                $scope.address = "";
            } else {

                $scope.address = $scope.model.endereco.Nome +
                    " , " + $scope.model.bairro.Nome +
                    " - " + $scope.model.cidade.Nome +
                    " - " + $scope.model.estado.Nome;
            }
        };

        $scope.resetModel = function() {
            $scope.address = "";
            $scope.cidadeData = [];
            $scope.bairroData = [];
            $scope.enderecoData = [];

            $scope.model = {
                cep: null,
                numero: null,
                complemento: "",
                estado: "",
                cidade: "",
                bairro: "",
                endereco: ""
            };

            $scope.updateAddressText();
        };

        $scope.resetEndereco = function () {
            $scope.address = "";
            
            $scope.model = {
                numero: null,
                complemento: "",
                estado: "",
                cidade: "",
                bairro: "",
                endereco: ""
            };
           
        };

        $scope.save = function() {

            $rootScope.isLoading = true;
            console.log($scope.data.EnderecoModel);

            $http.post("/Pedido/AddEndereco",
            {
                enderecoId: $scope.data.EnderecoModel.EnderecoId,
                    enderecoNum: $scope.model.numero,
                    enderecoComp: $scope.model.complemento,
                    endereco: $scope.model.endereco,
                    bairro: $scope.model.bairro
                })
                .error(function() {

                })
                .success(function(resp) {
                    if (resp.Success === true) {
                        $scope.resetModel();
                        $scope.read();
                        $scope.isAddEnabled = false;
                        alertify.success(resp.Message);
                    } else {
                        alertify.error(resp.Message);
                    }
                }).then(function() {
                    $rootScope.isLoading = false;
                });

        };

        $scope.calcFrete = function() {
            $scope.isFreteLoading = true;
            $scope.freteData = [];
            $http.get('/Frete/CalcCorreiosPreco')
                .success(function(resp) {
                    if (resp.length > 0) {
                        $scope.freteData = resp;
                    }
                })
                .then(function() {
                    $scope.isFreteLoading = false;
                });
        };

        $scope.setFreteModel = function(model) {

            $scope.freteModel = model;
            if (!$scope.freteModel) return;

            $http.post('/Pedido/SelectFrete', $scope.freteModel)
                .success(function(resp) {
                    $scope.isFreteSelected = resp === 'True';
                    Cart.freteVal = $scope.freteModel.Valor;
                    setTimeout(function() {
                        $scope.$apply();
                    }, 10);
                })
                .error(function() {
                    $scope.isFreteSelected = false;
                    $scope.freteModel = null;
                });
        };
        $($window).bind("hashchange", function() {

            if ($window.location.hash === "#entrega") {
                $scope.read();
            }

        });

        $scope.read();

    }
})(angular);