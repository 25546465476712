/**
 * Created by mayke on 30/09/15.
 */
(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .directive('spinner', spinner);

    function spinner() {
        var directive = {
            template: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" ' +
                'class="sevo-spinner-container" ' +
                'style="width:65px;height:65px;" ' +
                'viewBox="0 0 44 44">' +
                '<circle class="path" cx="22" cy="22" r="20" fill="none" stroke-width="4"></circle>' +
                '</svg>',
            restrict: 'E'
        };
        return directive;
    }
})(angular);