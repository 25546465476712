(function (angular) {

    'use strict';

    angular
        .module('friburgoeletro')
        .controller('RelatorioController', RelatorioController);

    /* @ngInject */
    function RelatorioController($http, alertify) {
        var vm = this;
        vm.reportData = [];

        $http.get('/Admin/ClientesRead')
            .then(function (res) {

                vm.revendedores = res.data.Data;

            }, function (err) {
                console.log(err.data);
            });

        vm.gerarRelatorio = function (params) {

            if (params.dataInicial && params.dataFinal) {
                $http.post('/Relatorio/RelatorioVendas', params)
                .then(function (res) {

                    vm.comissao = res.data.comissao;
                    vm.dataComissao = res.data.dataComissao;
                    vm.reportData = res.data.reportData;
                    vm.reportData.forEach(criarResumo);

                }, function (err) {
                    console.log(err);
                    alertify.error(err.data);
                });
            }

        }

        function criarResumo(item) {
            item.Pedidos.forEach(calcularComissao);

            var totais = _.pluck(item.Pedidos, 'Total');
            item.Total = 0;
            for (var i in totais) {
                item.Total += totais[i];
            }

            var comissoes = _.pluck(item.Pedidos, 'Comissao');
            item.Comissao = 0;
            for (var i in comissoes) {
                item.Comissao += comissoes[i];
            }
        }

        function calcularComissao(pedido) {
            pedido.Comissao = (pedido.Total - pedido.ValorFrete) * vm.comissao / 100;
        }

    }

})(angular);