(function (angular) {

    angular
        .module('friburgoeletro',
        [
            'ngAnimate',
            'mgcrea.ngStrap',
            'ngRoute',
            'ngResource',
            'creditCardInput',
            'ui.utils.masks',
            'ui.mask',
            'ngMask'
        ]);
})(angular);