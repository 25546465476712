(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .directive("isLoading", function() {
            return {
                restrict: "A",
                replace: true,
                scope: {
                    isLoading: '=isLoading'
                },
                link: link
            };
        });

    function link(scope, el, attrs) {
        var html = el.html();

        scope.$watch('isLoading', function() {
            if (scope.isLoading === true) {
                el.addClass('disabled');
                el.attr('disabled', 'disabled');
                el.prepend('<i class="fa fa-circle-o-notch fa-spin"></i>');
            } else {
                el.removeClass('disabled');
                el.removeAttr('disabled');
                el.html(html);
            }
        });
    }
})(angular);