(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .factory('Cart', cart);

    /* @ngInject */
    function cart($http, $rootScope, alertify) {
        var service = {};

        service.data = [];

        service.freteVal = 0;

        service.count = 0;

        service.read = function () {

            $http.get("/Cart/Read").error(function (data) {

            }).success(function (data) {
                service.data = data;

                _(data.Items).map(function (x) {
                    x.Thumb = service.getImageUrl(x.Thumb);
                });

                service.count = data.Items ? data.Items.length : 0;
            }).then(function () {

            });
        };

        service.add = function (prodId, qtd) {

            var data = {
                id: prodId,
                qtd: qtd
            };

            $http.post("/Cart/AddItem", data)
                .error(function (data, status, headers, config) {

                }).success(function (data) {

                    if (data.Success === true) {

                        $rootScope.$broadcast('Cart.add');
                        alertify.success('Item adicionado ao carrinho!');

                        service.read();
                    }

                }).error(function () {
                    alertify.success('Falha ao adicionar item ao carrinho!');
                });
        };

        service.remove = function (item) {

            $http.post("/Cart/RemoveItem", {
                id: item.Id,
                qtd: 0,
                removeAll: true
            }).error(function (data, status, headers, config) {

            }).success(function (data) {

                if (data === true) {

                    alertify.success("Item removido com sucesso!");

                    service.read();

                }

            }).error(function () {
                alertify.success('Falha ao remover item!');
            });

        };

        service.reset = function (cb) {
            $http.post("/Cart/Reset")
                .error(function (res, status, headers, config) {

                }).success(function (res) {
                    service.data = [];
                    service.count = 0;

                    if (cb)
                        cb(res);
                });
        };

        service.getImageUrl = function (relativeUrl) {
            if (relativeUrl && relativeUrl.length > 0) {
                return "/Image/Thumb/?src=" + encodeURI(relativeUrl) + "&w=84&h=84";
            } else {
                return null;
            }
        };

        service.total = function () {

            var total = 0;

            $(service.data.Items).each(function (i, el) {
                total += el.Quantidade * el.Preco;
            });

            return total;
        };

        service.totalWithFrete = function () {
            return service.total() + service.freteVal;
        };

        return service;
    }
})(angular);
