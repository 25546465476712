(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller("CatalogController", catalogController);

    /* @ngInject */
    function catalogController($scope, $http, $window) {

        var vm = this;

        vm.precoModel = {
            min: 0,
            max: 0
        };

        vm.corData = [];
        vm.orderData = [
            { name: "Menor Preço", value: "preco ASC" },
            { name: "Maior Preço", value: "preco DESC" },
            { name: "Descrição", value: "descricao ASC" },
        ];
        vm.orderModel = vm.orderData[0];

        vm.pageSizeData = [
            { name: "09 itens", value: 9 },
            { name: "12 itens", value: 12 },
            { name: "15 itens", value: 15 },
            { name: "30 itens", value: 30 }
        ];

        vm.pageSizeModel = vm.pageSizeData[0];

        vm.filterBy = "Descricao";
        vm.filter = filter;

        vm.init = init;

        function filter() {

            var reqObj = {
                paginate: vm.paginate,
                pageSize: vm.pageSizeModel.value,
                orderBy: vm.orderModel.value.split(" ")[0],
                orderDirection: vm.orderModel.value.split(" ")[1],
                cores: vm.corData
                    .filter(function(x) {
                        return x.isSelected;
                    })
                    .map(function(x) {
                        return x.Id;
                    }).join()
            };

            $window.location.href = $window.location.pathname + "?" + $.param(reqObj);
        };

        function init(paginate, pageSize, selectedColors, order) {

            vm.paginate = paginate || false;

            if (order && order.length > 0)
                vm.orderModel = _.find(vm.orderData, function(x) {
                    return x.value === order;
                });

            if (pageSize)
                vm.pageSizeModel = _.find(vm.pageSizeData, function(x) {
                    return x.value === pageSize;
                });

            if (vm.pageSize)
                $(vm.pageSizeData).each(function(i, el) {
                    if (el.value === vm.pageSize)
                        vm.pageSizeModel = el;
                });

            if (vm.order)
                $(vm.orderData).each(function(i, el) {
                    if (el.value === vm.order)
                        vm.orderModel = el;
                });
        };
    }
})(angular);