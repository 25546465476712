(function (angular) {
    'use strict';

    angular
        .module('friburgoeletro')
        .controller("TicketsController", ticketController);

    /* @ngInject */
    function ticketController($scope,$http) {

       
        $scope.data = {};
       
        $scope.read = function () {

            $http.get("/Ticket/Read").error(function (data) {

            }).success(function (data) {
                $scope.data = data;
               
            }).then(function () {

            });
        };

        $scope.read();

    }
})(angular);