
FastClick.notNeeded = function (layer) {
    var metaViewport;
    var chromeVersion;
    var blackberryVersion;
    var firefoxVersion;

    // Devices that don't support touch don't need FastClick
    if (typeof window.ontouchstart === 'undefined' || layer === null) {
        return true;
    }
    

    // Chrome version - zero for other browsers
    chromeVersion = +(/Chrome\/([0-9]+)/.exec(navigator.userAgent) || [, 0])[1];

    if (chromeVersion) {

        if (deviceIsAndroid) {
            metaViewport = document.querySelector('meta[name=viewport]');

            if (metaViewport) {
                // Chrome on Android with user-scalable="no" doesn't need FastClick (issue #89)
                if (metaViewport.content.indexOf('user-scalable=no') !== -1) {
                    return true;
                }
                // Chrome 32 and above with width=device-width or less don't need FastClick
                if (chromeVersion > 31 && document.documentElement.scrollWidth <= window.outerWidth) {
                    return true;
                }
            }

            // Chrome desktop doesn't need FastClick (issue #15)
        } else {
            return true;
        }
    }

    if (deviceIsBlackBerry10) {
        blackberryVersion = navigator.userAgent.match(/Version\/([0-9]*)\.([0-9]*)/);

        // BlackBerry 10.3+ does not require Fastclick library.
        // https://github.com/ftlabs/fastclick/issues/251
        if (blackberryVersion[1] >= 10 && blackberryVersion[2] >= 3) {
            metaViewport = document.querySelector('meta[name=viewport]');

            if (metaViewport) {
                // user-scalable=no eliminates click delay.
                if (metaViewport.content.indexOf('user-scalable=no') !== -1) {
                    return true;
                }
                // width=device-width (or less than device-width) eliminates click delay.
                if (document.documentElement.scrollWidth <= window.outerWidth) {
                    return true;
                }
            }
        }
    }

    // IE10 with -ms-touch-action: none or manipulation, which disables double-tap-to-zoom (issue #97)
    if (layer.style.msTouchAction === 'none' || layer.style.touchAction === 'manipulation') {
        return true;
    }

    // Firefox version - zero for other browsers
    firefoxVersion = +(/Firefox\/([0-9]+)/.exec(navigator.userAgent) || [, 0])[1];

    if (firefoxVersion >= 27) {
        // Firefox 27+ does not have tap delay if the content is not zoomable - https://bugzilla.mozilla.org/show_bug.cgi?id=922896

        metaViewport = document.querySelector('meta[name=viewport]');
        if (metaViewport && (metaViewport.content.indexOf('user-scalable=no') !== -1 || document.documentElement.scrollWidth <= window.outerWidth)) {
            return true;
        }
    }

    // IE11: prefixed -ms-touch-action is no longer supported and it's recomended to use non-prefixed version
    // http://msdn.microsoft.com/en-us/library/windows/apps/Hh767313.aspx
    if (layer.style.touchAction === 'none' || layer.style.touchAction === 'manipulation') {
        return true;
    }

    return false;
};

FastClick.attach(document.body);


(function () {
    $.fn.center = function () {
        this.css("top", ($(window).height() - this.outerHeight()) / 2 + "px");
        this.css("left", ($(window).width() - this.outerWidth()) / 2 + "px");
        this.css("position", "fixed");
        return this;
    };

    $("input[type=number]").livequery(function () {
        $(this).off("keydown.live");
        $(this).on("keydown.live", function (e) {

            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
            (e.keyCode === 65 && e.ctrlKey === true) ||
            (e.keyCode >= 35 && e.keyCode <= 40)) {

                return;
            }

            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
    });

    function handleInputFocus(element) {
        if ($(element).val() && $(element).val().length > 0)
            $(element).parent(".editor-field").prev(".editor-label").addClass("floating");

        $(element).on("change", function () {
            if ($(element).val() && $(element).val().length > 0) {
                $(element).parent(".editor-field").prev(".editor-label").addClass("floating");
            } else {
                $(element).parent(".editor-field").prev(".editor-label").removeClass("floating");
            }
        });

        $(element).on("focusin", function () {
            $(element).parent(".editor-field").prev(".editor-label").addClass("floating");
        });

        $(element).on("focusout", function () {
            if (!$(element).val() || $(element).val().length <= 0)
                $(element).parent(".editor-field").prev(".editor-label").removeClass("floating");
        });

    }

    $("input, textarea, select").each(function (i, el) {
        handleInputFocus(el);
    });

    $("input, textarea, select").livequery(function () {
        handleInputFocus(this);
    });

    alertify.defaults = {
        // dialogs defaults
        modal: true,
        basic: false,
        frameless: false,
        movable: true,
        resizable: true,
        closable: true,
        closableByDimmer: true,
        maximizable: true,
        startMaximized: false,
        pinnable: true,
        pinned: true,
        padding: true,
        overflow: true,
        maintainFocus: true,
        transition: 'zoom',
        autoReset: true,

        // notifier defaults
        notifier: {
            // auto-dismiss wait time (in seconds)
            delay: 50000,
            // default position
            position: 'top-right'
        },

        // language resources
        glossary: {
            // dialogs default title
            title: 'Mensagem',
            // ok button text
            ok: 'OK',
            // cancel button text
            cancel: 'Cancelar'
        },

        // theme settings
        theme: {
            // class name attached to prompt dialog input textbox.
            input: 'ajs-input',
            // class name attached to ok button
            ok: 'ajs-ok',
            // class name attached to cancel button
            cancel: 'ajs-cancel'
        }
    };

    $(document).ready(function () {

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        $(window).on("load", function () {
            $("#wrapper").animate({
                opacity: 1
            }, 500);

            var size = $(window).width() > 991 ? $(".produto .buy-cont").parent().width() + 30 : 0;

            $("#active-photo")
                .elevateZoom({
                    gallery: 'gallery',
                    cursor: 'pointer',
                    galleryActiveClass: 'active',
                    easing: true,
                    imageCrossfade: true,
                    tintColour: "#d5d5d5",
                    responsive: true,
                    zoomWindowPosition: $(window).width() > 991 ? "buy-cont" : null,
                    zoomWindowWidth: size,
                    zoomWindowHeight: size,
                    loadingIcon: '/Images/loader.svg'
                });
        });

        $(document).mouseup(function (e) {
            var container = $(".close-click-outside");

            if (!container.is(e.target)
                && container.has(e.target).length === 0)
            {
                container.hide();
            }
        });
    });
 
})();